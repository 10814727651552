import Api from 'lib/Api'
import io from 'lib/ioConfig'
import { store } from '../store'
import setToken from 'core/setToken'
import { logCustomEvent } from 'core/amplitude'

async function emailLogin({
  email,
  password,
  username,
  name,
  register,
  login,
  router,
  appState,
  noRedirect,
  myTrips
}) {
  const {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword
  } = await import('firebase/auth')
  const auth = await getAuth()

  try {
    let createdUser = null
    let err = false
    let firebaseUser = null

    if (register) {
      firebaseUser = await new Promise(resolve => {
        let tempUser = createUserWithEmailAndPassword(
          auth,
          email,
          password
        ).catch(error => {
          console.log('Error: ', error.code)
          err = error.code
        })
        resolve(tempUser)
      })
    } else if (login) {
      firebaseUser = await new Promise(resolve => {
        let tempUser = signInWithEmailAndPassword(
          auth,
          email,
          password
        ).catch(error => {
          console.log('Error: ', error.code)
          err = error.code
        })
        resolve(tempUser)
      })
    }
    if (firebaseUser) {
      createdUser = {
        uid: firebaseUser.user.uid,
        email: firebaseUser.user.email
      }
    }

    if (err && err.length > 0) {
      logCustomEvent({
        name: 'URegistrationStatus',
        data: {
          status: 'failed',
          signup_medium: 'web',
          timestamp: new Date(),
          user_id: 'None',
          is_host: false,
          is_new_user: 'None',
          user_name: 'None'
        }
      })
    }

    if (err && err === 'auth/weak-password') {
      return { weakLogin: true }
    } else if (err && err.includes('auth/invalid-email')) {
      return { message: 1 }
    } else if (err && err === 'auth/email-already-in-use') {
      return { message: 2 }
    } else if (err.length > 0) {
      return { message: 3 }
    } else if (createdUser) {
      if (
        username &&
        username.length > 0 &&
        name &&
        name.length > 0
      ) {
        createdUser['username'] = username
        createdUser['name'] = name
      }

      const res = await Api.post('/users/emailLogin', createdUser)
      if (!res) return

      let {
        user,
        token,
        trips,
        reviews,
        notifications,
        blockedUsers,
        isNewRegister
      } = res

      logCustomEvent({
        name: 'URegistrationStatus',
        data: {
          user_id: user._id + '',
          is_host: user && user.type === 'host' ? true : false,
          status: 'success',
          is_new_user: isNewRegister,
          signup_medium: 'web',
          user_name: user.name ? user.name : name ? name : null,
          timestamp: new Date()
        }
      })

      user = { ...user, reviews, notifications }

      setToken(token)
      io.connect()

      await store.dispatch({
        type: 'SET_USER',
        payload: {
          ...user,
          onboardingStep: 0
        }
      })

      let tempMyTrips = []
      if (noRedirect) {
        tempMyTrips =
          myTrips && myTrips.length > 0 ? [...myTrips] : []
        if (myTrips && myTrips.length > 0) {
          tempMyTrips = [...myTrips]
          if (trips && trips.length > 0) {
            trips.map(trip => {
              if (
                !tempMyTrips.some(
                  tmt => tmt._id + '' === trip._id + ''
                )
              ) {
                tempMyTrips.push(trip)
              }
            })
          }
        } else {
          tempMyTrips = trips && trips.length > 0 ? [...trips] : []
        }
      } else {
        tempMyTrips = trips
      }

      await store.dispatch({
        type: 'SET_MY_TRIPS',
        payload: tempMyTrips
      })

      await store.dispatch({
        type: 'SET_BLOCKUSER',
        payload: blockedUsers
      })

      if (noRedirect) return
      if (register && !user.onboarded) {
        router.push('/onboarding')
      } else if (user && !user.onboarded) {
        router.push('/onboarding')
      } else {
        if (
          appState &&
          appState.redirectTo &&
          appState.redirectTo.length > 0
        ) {
          let tempRedirect = appState.redirectTo
          store.dispatch({
            type: 'SET_REDIRECT',
            payload: null
          })
          router.push(`${tempRedirect}`)
        } else {
          router.push('/explore')
        }
      }
    }
  } catch (e) {
    console.log('Error: ', e)
    logCustomEvent({
      name: 'URegistrationStatus',
      data: {
        status: 'failed',
        signup_medium: 'web',
        timestamp: new Date(),
        user_id: 'None',
        is_host: false,
        is_new_user: 'None',
        user_name: 'None'
      }
    })
    return { message: 3 }
  }
}
export default emailLogin
