import io from 'lib/ioConfig'

let globalStreamUrl

async function checkConnection() {
  let connectedToSockets = io.checkConnection()
  return connectedToSockets
}

async function joinStream({ hostId, tripId }) {
  let connectedToSockets = io.checkConnection()
  io.joinStream({ hostId, tripId })
  return connectedToSockets
}

async function startStream({ tripId, isRecording, recordingStats }) {
  io.startStream({ tripId: tripId, isRecording, recordingStats })
}

async function leaveStream({ tripId }) {
  globalStreamUrl = null
  io.leaveStream(tripId)
}

async function locationShare(data) {
  io.locationShare(data)
}

const streaming = {
  joinStream,
  leaveStream,
  startStream,
  getStream: () => globalStreamUrl,
  locationShare,
  checkConnection
}
export default streaming
